
import { ExportToCsv } from "export-to-csv";
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getUplinkList } from "@/store/api/devices";
import moment from "moment";
import { useRoute } from "vue-router";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  variant_id: any;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "repository",
  components: {
    KTDatatable,
  },
  setup() {
    const route = useRoute();
    const uplinkList = ref<any[]>([]);
    const loading = ref<boolean>(true);
    const search = ref<string>("");
    const cardData = ref<string>("");
    const jsonData = ref<any[]>([]);
    const listData = ref<any[]>([]);
    const headerConfig = ref([
      {
        name: "Time",
        key: "last_seen_ts",
      },
      {
        name: "Hex",
        key: "hex",
      },
      {
        name: "Distance",
        key: "json",
      },
      {
        name: "Status Code",
        key: "statusCode",
      },
      {
        name: "Signal Rate",
        key: "signalRateBits",
      },
      {
        name: "Ref.sped Number",
        key: "refSpadNumberBits",
      },
      {
        name: "Ambient",
        key: "ambientRate",
      },
    ]);
    const total = ref<number>(0);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      variant_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    onMounted(async () => {
      pagination.value.variant_id = route.params.id;
      loadData();
      setCurrentPageBreadcrumbs("Uplink", [""]);
    });
    const loadData = async () => {
      loading.value = true;
      let uplinkData = await getUplinkList(pagination.value);

      for (let i = 0; i < uplinkData.uplinks.length; i++) {
        let obj = uplinkData.uplinks[i];
        const data = {
          Time: obj.created_at,
          HEX: obj.hex,
          Distance: obj.json.rawDistance,
          AMBIENT: obj.json.ambientRate,
          Status_Code: obj.json.statusCode,
          REF_SPED_NUMBER: obj.json.refSpadNumberBits,
          Signal_Rate: obj.json.signalRateBits,
        };
        jsonData.value.push(data);
      }
      uplinkList.value = uplinkData.uplinks;
      total.value = uplinkData.total_number_of_records;
      loading.value = false;
    };
    const UplinkListing = async () => {
      const getAlluplinkdata = await getUplinkList({
        variant_id: route.params.id,
        all_data: true,
      });
      for (let i = 0; i < getAlluplinkdata.uplinks.length; i++) {
        let obj = getAlluplinkdata.uplinks;
        let data = {
          Time: obj[i].created_at,
          HEX: obj[i].hex,
          Distance: obj[i].json.rawDistance,
          AMBIENT: obj[i].json.ambientRate,
          Status_Code: obj[i].json.statusCode,
          REF_SPED_NUMBER: obj[i].json.refSpadNumberBits,
          Signal_Rate: obj[i].json.signalRateBits,
        };
        listData.value.push(data);
      }
      if (getAlluplinkdata.uplinks.length > 0) {
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(listData.value);
      } else {
        console.log("a;sdjf;asdhf;ashdf;asdhf;asdhfad");
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv([{ key: "No data available" }]);
      }
    };

    const exportCsvFile = () => {
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(jsonData.value);
    };
    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD HH:MM:SS");
    };

    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };
    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };
    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };

    return {
      uplinkList,
      headerConfig,
      total,
      loading,
      search,
      exportCsvFile,
      pagination,
      UplinkListing,
      cardData,
      currentChange,
      searchItems,
      itemsPerPageChange,
      dateTime,
    };
  },
});
